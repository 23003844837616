@import './variables.scss';

#content {
  scroll-margin-top: 6.5rem;
}

.p-t-100{
  padding-top:100px;
}
.p-t-75 {
  padding-top: 75px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-100{
  padding-bottom:100px;
}
.p-b-75{
  padding-bottom:75px;
}
.p-b-50{
  padding-bottom:50px;
}
.p-b-25{
  padding-bottom:20px;
}
.text-center{
  text-align: center;
}

.bg-white{
  background-color:#fff;
}
.bg-gray{
  background-color:#fafafa !important;
}

.intro{
  h1{
      font-weight: 600;
  }
}

.map{
  height:400px;
}

.juicer-feed li{
  border-radius: 5px !important;

}
.igfeed, .bg-white{
  background-color:#fff;
}

a.btn-standard {
  display: inline-block;
  color: #fff !important;
  background-color: $mainColor;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.05em;
  transition: background-color 250ms ease-in-out;
  text-transform: uppercase;
  border: none;
}

a.btn-grey {
  display: inline-block;
  color: #fff !important;
  background-color: black;
  padding: 15px 50px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.05em;
  transition: background-color 250ms ease-in-out;
  text-transform: uppercase;
  border: none;
}

.highlight {
  color: $mainColor;
}

._inline-form {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
  max-width: none !important;
}

.btn-blue {
  margin-bottom: 0.5rem;
}

.bg-gray #_form_6609613699851_ {
  background-color: inherit !important;
}

.bg-gray #_form_66096C1AEBF58_ {
  background-color: inherit !important;
}