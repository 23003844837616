.testimonial-header {
  margin-bottom: 25px;
}

.testimonial-carousel {
  margin-bottom: 50px;

  .image {
    margin: 0 auto;
    margin-bottom: 0.5em;
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 100%;
    background-color: grey;
    background-size: cover;
    background-position: center;
    line-height: 1.2em;
  }
  
  .quote {
    font-size: 1.5em;
    color: #666;
    font-style: italic;
    font-weight: 500;
    max-width: 35em;
    line-height: 1.5em;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
  }

  .name {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 3em;
  }

  .title {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: #ff6600;
  }

  .btn-grey {
    margin-top: 2em;
  }

  .testimonials {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    
    .testimonial {
      flex: 1 0 100%;
      width: 100%;
    }
  }

  .arrow {
    font-size: 2.5em;
    color: #ff6600;
    z-index: 1;

    &.disabled {
      color: #ccc;
    }

    display: block;
    cursor: pointer;
    line-height: 2em;
    padding: 0.25em;

    &.left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.testimonial-carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 50px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ccc;
    margin: 0 0.5em;
    cursor: pointer;

    &.active {
      background-color: #ff6600;
    }
  }
}