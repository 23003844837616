.banner{
    @media (min-width:992px){
        margin-top: 82px;
    }
    @media (max-width:991px){
        margin-top: 49px;
    }
    

    width: 100%;
    height: 70vh;
    min-height: 400px;
    position: relative;

    .banner-img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        background-image: url('./banner.jpg');


    }

    .banner-content{
        position: absolute;
        width:800px;
        left:50%;
        top:50%;
        text-align: center;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

        @media screen and (max-width:991px){
            left:15px;
            right:15px;
            width:auto;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
        }

        color:#fff;
        @media screen and (max-width:767px){
            left:15px;
            right:15px;
            width:auto;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
        }

        h2, h1{
            letter-spacing: 0em;
            font-size:54px;
            margin-top:0;
            margin-bottom:20px;
            font-weight: 700;
            @media screen and (max-width:767px){
                font-size:35px;
            }
        }
        p{
            font-weight: 300;
            font-size:20px;
            letter-spacing: 0.0em;
        }
    }
}