.employeepage{

    form{

        label{
            font-weight: 400;

            &[type="checkbox"], &[type="radio"]{
                font-weight: 300;
            }

            font-size: 110%;
        }

        button.btn-blue{
            border:none;
            appearance: none;
        }
    }

    .submit-row {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        gap: 3rem;
    }

    .job {
        background-color: #ebebeb;
        margin-top: 1em;
        margin-bottom: 1em;
        padding: 1em;

        .job-title {
            display: flex;

            h3 {
                flex: 1;
            }

            div {
                font-weight: bold;
            }
        }

        .read-more {
            margin-top: 1em;
            margin-bottom: 1em;
            font-weight: bold;
            font-size: 105%;
        }
    }
}