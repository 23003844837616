@import 'variables.scss';

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

* {
	box-sizing: border-box;
}

body{
//   overflow-y: scroll;
}
html{
    overflow-x: hidden;
    overflow-y: auto;   
}
html, body{
  font-family: 'Montserrat', sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  -webkit-overflow-scrolling: touch;

  margin: 0;
	padding: 0;
	min-height: 100%;
  height: 100%;
	// overflow-x: hidden;


      font-weight: 300;
      font-size:18px;
      line-height: 27px;

      h1{
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: 600;
      }

      h2{
          margin-top:0;
          margin-bottom:30px;
          font-size:34px;
          font-weight: 600;
      }
      h3{
          font-size: 27px;
          line-height:1.5;
          font-weight: 600;
      }
      h4{
          font-size:21px;
          font-weight: 600;
      }

      // Blue uppercase heading
      h6{
          color: $mainColor;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          font-weight: 500;
          margin-top:0;
          font-size:16px;
      }
      @media screen and (max-width:767px){

          h1{
              font-size:28px;
              margin-bottom:25px;
          }
          h2{
              font-size:27px;
              margin-bottom:25px;
          }
          h3{
              font-size:22px;
          }
          h3{
              font-size:18px;
          }

      }
      a:not(.btn-blue){
          color: $mainColor;
          position: relative;
  
          
      }
      .features, .contactpage{
        a:not(.btn-blue){
            &:after{
                content:'';
                position: absolute;
                left:0;
                width:0;
                bottom:0;
                height:1px;
                background-color: $mainColor;
                transition: all 250ms ease-in-out;
            }
            &:hover:after{
                width:100%;
            }
    
            &:hover, &:focus, &:active{
                text-decoration: none;
                color: $mainColor;
            }
          }
      }
  
      // Global
      .text-center{
          text-align: center;
      }
  
      .p-t-100{
          padding-top:100px;
      }
      .p-b-100{
          padding-bottom:100px;
      }
      .p-b-50{
          padding-bottom:50px;
      }
      @media screen and (max-width:767px){
          .p-t-100{
              padding-top:80px;
          }
          .p-b-100{
              padding-bottom:80px;
          }

      }
  
      .btn-blue{
          color:#fff;
          text-transform: uppercase;
          background-color: $mainColor;
          display:inline-block;
          padding:10px 20px;
          font-size:13px;
          font-weight: 500;
          letter-spacing: 0.05em;
          transition: background-color 250ms ease-in-out;
  
          &:hover{
              background-color: $black;
          }
          &:hover, &:focus, &:active{
              text-decoration: none;
              outline: none;
          }
  
        &.grey {
            background-color: #858585;
        }
      }
  

  
  
}