@import '../../variables.scss';

// Footer
.footer{
    padding-top:100px;
    background-color: black;
    color: #fff;
    font-size: 14px;
    line-height: 1.42857;

    img.logo{
        width:300px;
        padding-bottom:30px;
    }

    .upper{
        padding-bottom:100px;
        border-bottom:2px solid rgba(255,255,255,0.1);
    }
    .lower{
        padding-top:30px;
        padding-bottom:30px;

        p{
            margin:0;
        }
    }
    @media screen and (max-width:991px){
                
        padding-top:80px;     
        .upper{
            padding-bottom:40px;
        }
                
    }

    .footer-nav{
        width:100%;
        padding:0;
        overflow: hidden;
        position: relative;
        @media (min-width:768px){
            text-align: center;
        }
        margin:0;

        & > li{
            display:inline-block;
            padding:5px 10px 5px 0;
            @media (min-width:768px){
                padding:5px 10px;
            }
            @media screen and (max-width:991px){
                display: block;
            }

            & > a{
                text-transform: uppercase;
                font-weight: 500;
                color:#fff;

                &:hover, &:focus, &:active{
                    color:#fff;
                    text-decoration: none;
                }
            }

            &.current{
                a{
                    font-weight: 300;
                }
                
            }
        }
    }

    .some{
        @media (min-width:768px){
            text-align: right;
        }
        

        .btn-blue{
            margin-top:30px;

            &:hover, &:focus, &:active{
                background-color:$mainColor;
            }
        }
    }

    .some-list{
        width:100%;
        padding:0;
        overflow: hidden;
        position: relative;
        text-align: right;
        margin:0;

        & > li{
            display:inline-block;
            // padding:0 0 0 10px;
            

            & > a{
                color:#fff;
                display:block;
                padding:0px 10px;
                font-size:20px;

                &:hover, &:focus, &:active{
                    color:#fff;
                    text-decoration: none;
                }
            }

            &:last-child{
                & > a{
                    padding-right:0;
                }
            }

            

        }

        
    }

    .right{
        
        @media (min-width:768px){
            text-align: right;
        }
        a{
            color:#fff;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width:620px){

        .upper{
            & > div{
                width:100%;

                .footer-nav{
                    text-align: left;
                    padding-top:30px;
                    padding-bottom:15px;
                    li{
                        padding-left:0;
                    }
                }
                .some-list{
                    text-align: left;
                    & > li{
                        & > a{
                            padding-left:0;
                            padding-right:20px;
                        }
                    }
                }
            }
        }
        .lower{
            & > div{
                width:100%;
                text-align: center;

                &.right{
                    padding-top:15px;
                }
            }
        }
    }
}