.employeepage{

    form{

        label{
            font-weight: 400;

            &[type="checkbox"], &[type="radio"]{
                font-weight: 300;
            }

            font-size: 110%;
        }

        button.btn-blue{
            border:none;
            appearance: none;
        }
    }

    .submit-row {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        gap: 3rem;
    }
}