@import '../../variables.scss';

.referencespage{

    .referenssit{
        @media (max-width:991px){
            & > div:first-child{
                padding-bottom:50px;
            }
        }
        @media (min-width:992px){
            img{
                height:192px;
            }
        }
        @media (min-width:768px){
            img{
                height:140px;
            }
        }
        @media (min-width:576px){
            img{
                height:100px;
            }
        }
        @media (max-width:575px){
            img{
                height:80px;
            }
        }
        img{
            
            opacity:1;
            -webkit-transition: opacity 250ms ease-in-out;
            transition: opacity 250ms ease-in-out;
            
        }
        p{
            padding-top:20px;



        }
        a:hover, a:focus, a:active{
            img{
                opacity:0.7;
            }

        }
    }
}