.homepage {
  .btn-blue {
    min-width: 21em;
    text-align: center;

    &.grey {
      background-color: black;
    }
  }

  .intro {
    h2 {
      color: #ff6600;
      font-weight: normal;
    }
  }

  .services-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    a {
      width: calc(50% - 5px);
      aspect-ratio: 1 / 1;
      color: white;
      font-weight: bold;

      background-size: cover;
      background-position: center;
      font-size: 90%;

      &:hover {
        color: white;
        text-decoration: none;
      }

      span {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: rgba(0,0,0,0.6);
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: rgba(0,0,0,0.4);
        }
      }
    }

    @media(min-width: 768px) {
      .mobile {
        display: none;
      }
    }

    @media (max-width: 768px) {
      .desktop {
        display: none;
      }

      flex-direction: column;

      a {
        width: 100%;
      }
    }
  }

  .social-links {
    list-style-type: none;

    li {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
   
    a {
      margin-right: 2rem;
      margin-left: 2rem;
      
      border-radius: 50%;
      display: inline-flex;
      align-items: center; 
      justify-content: center;

      border-radius: 50%;
      background-color: black;
      width: 7rem;
      height: 7rem;
      text-align: center;
      line-height: 3rem;

      color: #ff6600;
      font-size: 4rem;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 1200px) {
      a {
        font-size: 2rem;

        width: 3.5rem;
        height: 3.5rem;
        line-height: 1.5rem;

        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}