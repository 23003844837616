.contactpage{
    h2{
        margin-top:30px;
    }

    table{
        border: none !important;

        td {
            border: none;
            width: 33.3%;
        }

        tr {
            border: none;
        }

        h3{
            margin:0;
        }
    }
}