.contactPerson {
  text-align: center;

  .image {
    margin: 0 auto;
    margin-bottom: 0.5em;
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 100%;
    background-color: grey;
    background-size: cover;
    background-position: center;
    line-height: 1.2em;
  }
}