$mainColor: #ff6600;
;
$gray: #fafafa;
$black: #333333;

$gray-bg: $gray;
$black-bg: $black;

$text-color: #333;

