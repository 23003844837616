.reference-header {
    margin-bottom: 25px;
  }
  
  .reference-carousel {
    padding-left: 50px;
    padding-right: 50px;
     
    .name {
      font-size: 1.25em;
      font-style: italic;
      margin-top: 3em;
    }
  
    .title {
      font-size: 1.25em;
      font-style: italic;
      margin-top: 0.5em;
      margin-bottom: 1em;
      color: #ff6600;
    }
  
    .btn-grey {
      margin-top: 2em;
    }
  
    .references {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      
      .page {
        flex: 1 0 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;

        .reference {
          width: 200px;
          height: 188px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          text-indent: -9999px;
        }
      }
    }
  
    .arrow {
      font-size: 2.5em;
      color: #ff6600;
      z-index: 1;
  
      &.disabled {
        color: #ccc;
      }
  
      display: block;
      cursor: pointer;
      line-height: 2em;
      padding: 0.25em;
  
      &.left {
        position: absolute;
        left: 0;
        top: 40%;
        transform: translateY(-50%);
      }
  
      &.right {
        position: absolute;
        right: 0;
        top: 40%;
        transform: translateY(-50%);
      }
    }
  }
  
  .reference-carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #ccc;
      margin: 0 0.5em;
      cursor: pointer;
  
      &.active {
        background-color: #ff6600;
      }

      @media (max-width: 768px) {
        margin: 0 0.25em;
      }
    }
  }