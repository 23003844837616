@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
* {
  box-sizing: border-box; }

html {
  overflow-x: hidden;
  overflow-y: auto; }

html, body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px; }
  html h1, body h1 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: 600; }
  html h2, body h2 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: 600; }
  html h3, body h3 {
    font-size: 27px;
    line-height: 1.5;
    font-weight: 600; }
  html h4, body h4 {
    font-size: 21px;
    font-weight: 600; }
  html h6, body h6 {
    color: #ff6600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 500;
    margin-top: 0;
    font-size: 16px; }
  @media screen and (max-width: 767px) {
    html h1, body h1 {
      font-size: 28px;
      margin-bottom: 25px; }
    html h2, body h2 {
      font-size: 27px;
      margin-bottom: 25px; }
    html h3, body h3 {
      font-size: 22px; }
    html h3, body h3 {
      font-size: 18px; } }
  html a:not(.btn-blue), body a:not(.btn-blue) {
    color: #ff6600;
    position: relative; }
  html .features a:not(.btn-blue):after, html .contactpage a:not(.btn-blue):after, body .features a:not(.btn-blue):after, body .contactpage a:not(.btn-blue):after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    bottom: 0;
    height: 1px;
    background-color: #ff6600;
    transition: all 250ms ease-in-out; }
  html .features a:not(.btn-blue):hover:after, html .contactpage a:not(.btn-blue):hover:after, body .features a:not(.btn-blue):hover:after, body .contactpage a:not(.btn-blue):hover:after {
    width: 100%; }
  html .features a:not(.btn-blue):hover, html .features a:not(.btn-blue):focus, html .features a:not(.btn-blue):active, html .contactpage a:not(.btn-blue):hover, html .contactpage a:not(.btn-blue):focus, html .contactpage a:not(.btn-blue):active, body .features a:not(.btn-blue):hover, body .features a:not(.btn-blue):focus, body .features a:not(.btn-blue):active, body .contactpage a:not(.btn-blue):hover, body .contactpage a:not(.btn-blue):focus, body .contactpage a:not(.btn-blue):active {
    text-decoration: none;
    color: #ff6600; }
  html .text-center, body .text-center {
    text-align: center; }
  html .p-t-100, body .p-t-100 {
    padding-top: 100px; }
  html .p-b-100, body .p-b-100 {
    padding-bottom: 100px; }
  html .p-b-50, body .p-b-50 {
    padding-bottom: 50px; }
  @media screen and (max-width: 767px) {
    html .p-t-100, body .p-t-100 {
      padding-top: 80px; }
    html .p-b-100, body .p-b-100 {
      padding-bottom: 80px; } }
  html .btn-blue, body .btn-blue {
    color: #fff;
    text-transform: uppercase;
    background-color: #ff6600;
    display: inline-block;
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05em;
    transition: background-color 250ms ease-in-out; }
    html .btn-blue:hover, body .btn-blue:hover {
      background-color: #333333; }
    html .btn-blue:hover, html .btn-blue:focus, html .btn-blue:active, body .btn-blue:hover, body .btn-blue:focus, body .btn-blue:active {
      text-decoration: none;
      outline: none; }
    html .btn-blue.grey, body .btn-blue.grey {
      background-color: #858585; }

.banner {
  width: 100%;
  height: 70vh;
  min-height: 400px;
  position: relative; }
  @media (min-width: 992px) {
    .banner {
      margin-top: 82px; } }
  @media (max-width: 991px) {
    .banner {
      margin-top: 49px; } }
  .banner .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url(/static/media/banner.291eba83.jpg); }
  .banner .banner-content {
    position: absolute;
    width: 800px;
    left: 50%;
    top: 50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff; }
    @media screen and (max-width: 991px) {
      .banner .banner-content {
        left: 15px;
        right: 15px;
        width: auto;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    @media screen and (max-width: 767px) {
      .banner .banner-content {
        left: 15px;
        right: 15px;
        width: auto;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    .banner .banner-content h2, .banner .banner-content h1 {
      letter-spacing: 0em;
      font-size: 54px;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: 700; }
      @media screen and (max-width: 767px) {
        .banner .banner-content h2, .banner .banner-content h1 {
          font-size: 35px; } }
    .banner .banner-content p {
      font-weight: 300;
      font-size: 20px;
      letter-spacing: 0.0em; }

.reference-header {
  margin-bottom: 25px; }

.reference-carousel {
  padding-left: 50px;
  padding-right: 50px; }
  .reference-carousel .name {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 3em; }
  .reference-carousel .title {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: #ff6600; }
  .reference-carousel .btn-grey {
    margin-top: 2em; }
  .reference-carousel .references {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row; }
    .reference-carousel .references .page {
      flex: 1 0 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      grid-gap: 10px;
      gap: 10px; }
      .reference-carousel .references .page .reference {
        width: 200px;
        height: 188px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        text-indent: -9999px; }
  .reference-carousel .arrow {
    font-size: 2.5em;
    color: #ff6600;
    z-index: 1;
    display: block;
    cursor: pointer;
    line-height: 2em;
    padding: 0.25em; }
    .reference-carousel .arrow.disabled {
      color: #ccc; }
    .reference-carousel .arrow.left {
      position: absolute;
      left: 0;
      top: 40%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .reference-carousel .arrow.right {
      position: absolute;
      right: 0;
      top: 40%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.reference-carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 1em; }
  .reference-carousel-dots .dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ccc;
    margin: 0 0.5em;
    cursor: pointer; }
    .reference-carousel-dots .dot.active {
      background-color: #ff6600; }
    @media (max-width: 768px) {
      .reference-carousel-dots .dot {
        margin: 0 0.25em; } }

.testimonial-header {
  margin-bottom: 25px; }

.testimonial-carousel {
  margin-bottom: 50px; }
  .testimonial-carousel .image {
    margin: 0 auto;
    margin-bottom: 0.5em;
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 100%;
    background-color: grey;
    background-size: cover;
    background-position: center;
    line-height: 1.2em; }
  .testimonial-carousel .quote {
    font-size: 1.5em;
    color: #666;
    font-style: italic;
    font-weight: 500;
    max-width: 35em;
    line-height: 1.5em;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px; }
  .testimonial-carousel .name {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 3em; }
  .testimonial-carousel .title {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: #ff6600; }
  .testimonial-carousel .btn-grey {
    margin-top: 2em; }
  .testimonial-carousel .testimonials {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row; }
    .testimonial-carousel .testimonials .testimonial {
      flex: 1 0 100%;
      width: 100%; }
  .testimonial-carousel .arrow {
    font-size: 2.5em;
    color: #ff6600;
    z-index: 1;
    display: block;
    cursor: pointer;
    line-height: 2em;
    padding: 0.25em; }
    .testimonial-carousel .arrow.disabled {
      color: #ccc; }
    .testimonial-carousel .arrow.left {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .testimonial-carousel .arrow.right {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.testimonial-carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 50px; }
  .testimonial-carousel-dots .dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ccc;
    margin: 0 0.5em;
    cursor: pointer; }
    .testimonial-carousel-dots .dot.active {
      background-color: #ff6600; }

.homepage .btn-blue {
  min-width: 21em;
  text-align: center; }
  .homepage .btn-blue.grey {
    background-color: black; }

.homepage .intro h2 {
  color: #ff6600;
  font-weight: normal; }

.homepage .services-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px; }
  .homepage .services-grid a {
    width: calc(50% - 5px);
    aspect-ratio: 1 / 1;
    color: white;
    font-weight: bold;
    background-size: cover;
    background-position: center;
    font-size: 90%; }
    .homepage .services-grid a:hover {
      color: white;
      text-decoration: none; }
    .homepage .services-grid a span {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      transition: background-color 0.3s ease-in-out; }
      .homepage .services-grid a span:hover {
        background-color: rgba(0, 0, 0, 0.4); }
  @media (min-width: 768px) {
    .homepage .services-grid .mobile {
      display: none; } }
  @media (max-width: 768px) {
    .homepage .services-grid {
      flex-direction: column; }
      .homepage .services-grid .desktop {
        display: none; }
      .homepage .services-grid a {
        width: 100%; } }

.homepage .social-links {
  list-style-type: none; }
  .homepage .social-links li {
    margin: 0;
    padding: 0;
    display: inline-block; }
  .homepage .social-links a {
    margin-right: 2rem;
    margin-left: 2rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: black;
    width: 7rem;
    height: 7rem;
    text-align: center;
    line-height: 3rem;
    color: #ff6600;
    font-size: 4rem; }
    .homepage .social-links a:hover {
      text-decoration: none; }
  @media (max-width: 1200px) {
    .homepage .social-links a {
      font-size: 2rem;
      width: 3.5rem;
      height: 3.5rem;
      line-height: 1.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }

.contactPerson {
  text-align: center; }
  .contactPerson .image {
    margin: 0 auto;
    margin-bottom: 0.5em;
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 100%;
    background-color: grey;
    background-size: cover;
    background-position: center;
    line-height: 1.2em; }

.accordion .card .card-header {
  background-color: #fafafa; }
  .accordion .card .card-header button {
    color: #ff6600;
    text-align: left; }

.servicespage {
  padding-bottom: 3em; }
  .servicespage h4 {
    font-weight: normal;
    margin-bottom: 1.5em;
    color: #ff6600; }
  .servicespage .readMore {
    margin-top: 2em; }

.service-header {
  font-weight: bold;
  margin-bottom: 0.5em; }

.testimonial-header {
  margin-bottom: 25px; }

.testimonial-carousel {
  margin-bottom: 50px; }
  .testimonial-carousel .image {
    margin: 0 auto;
    margin-bottom: 0.5em;
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 100%;
    background-color: grey;
    background-size: cover;
    background-position: center;
    line-height: 1.2em; }
  .testimonial-carousel .quote {
    font-size: 1.5em;
    color: #666;
    font-style: italic;
    font-weight: 500;
    max-width: 35em;
    line-height: 1.5em;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px; }
  .testimonial-carousel .name {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 3em; }
  .testimonial-carousel .title {
    font-size: 1.25em;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: #ff6600; }
  .testimonial-carousel .btn-grey {
    margin-top: 2em; }
  .testimonial-carousel .testimonials {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row; }
    .testimonial-carousel .testimonials .testimonial {
      flex: 1 0 100%;
      width: 100%; }
  .testimonial-carousel .arrow {
    font-size: 2.5em;
    color: #ff6600;
    z-index: 1;
    display: block;
    cursor: pointer;
    line-height: 2em;
    padding: 0.25em; }
    .testimonial-carousel .arrow.disabled {
      color: #ccc; }
    .testimonial-carousel .arrow.left {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .testimonial-carousel .arrow.right {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.testimonial-carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 50px; }
  .testimonial-carousel-dots .dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ccc;
    margin: 0 0.5em;
    cursor: pointer; }
    .testimonial-carousel-dots .dot.active {
      background-color: #ff6600; }

.service-page-other-links {
  padding-bottom: 50px; }
  .service-page-other-links h2 {
    margin-bottom: 1.5em; }
  .service-page-other-links ul {
    list-style-type: none;
    font-size: 140%;
    margin-left: 0;
    padding-left: 0; }
    .service-page-other-links ul li {
      margin-bottom: 1em;
      margin-left: 0; }

.contactpage h2 {
  margin-top: 30px; }

.contactpage table {
  border: none !important; }
  .contactpage table td {
    border: none;
    width: 33.3%; }
  .contactpage table tr {
    border: none; }
  .contactpage table h3 {
    margin: 0; }

@media (max-width: 991px) {
  .referencespage .referenssit > div:first-child {
    padding-bottom: 50px; } }

@media (min-width: 992px) {
  .referencespage .referenssit img {
    height: 192px; } }

@media (min-width: 768px) {
  .referencespage .referenssit img {
    height: 140px; } }

@media (min-width: 576px) {
  .referencespage .referenssit img {
    height: 100px; } }

@media (max-width: 575px) {
  .referencespage .referenssit img {
    height: 80px; } }

.referencespage .referenssit img {
  opacity: 1;
  transition: opacity 250ms ease-in-out; }

.referencespage .referenssit p {
  padding-top: 20px; }

.referencespage .referenssit a:hover img, .referencespage .referenssit a:focus img, .referencespage .referenssit a:active img {
  opacity: 0.7; }

.employeepage form label {
  font-weight: 400;
  font-size: 110%; }
  .employeepage form label[type="checkbox"], .employeepage form label[type="radio"] {
    font-weight: 300; }

.employeepage form button.btn-blue {
  border: none;
  -webkit-appearance: none;
          appearance: none; }

.employeepage .submit-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem; }

.employeepage form label {
  font-weight: 400;
  font-size: 110%; }
  .employeepage form label[type="checkbox"], .employeepage form label[type="radio"] {
    font-weight: 300; }

.employeepage form button.btn-blue {
  border: none;
  -webkit-appearance: none;
          appearance: none; }

.employeepage .submit-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem; }

.employeepage form label {
  font-weight: 400;
  font-size: 110%; }
  .employeepage form label[type="checkbox"], .employeepage form label[type="radio"] {
    font-weight: 300; }

.employeepage form button.btn-blue {
  border: none;
  -webkit-appearance: none;
          appearance: none; }

.employeepage .submit-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem; }

.employeepage .job {
  background-color: #ebebeb;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em; }
  .employeepage .job .job-title {
    display: flex; }
    .employeepage .job .job-title h3 {
      flex: 1 1; }
    .employeepage .job .job-title div {
      font-weight: bold; }
  .employeepage .job .read-more {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 105%; }

.employeepage form label {
  font-weight: 400;
  font-size: 110%; }
  .employeepage form label[type="checkbox"], .employeepage form label[type="radio"] {
    font-weight: 300; }

.employeepage form button.btn-blue {
  border: none;
  -webkit-appearance: none;
          appearance: none; }

.employeepage .submit-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem; }

.accordion .card .card-header {
  background-color: #fafafa; }
  .accordion .card .card-header button {
    color: #ff6600;
    text-align: left; }

.recruitment-padder {
  height: 3.6em;
  display: none; }
  .recruitment-padder.visible {
    display: block; }

.navbar {
  background-color: #fff;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #eee;
  margin-bottom: 0; }
  .navbar .dropdown-toggle::after {
    border: none; }
  .navbar .nav-item.dropdown:hover .dropdown-menu {
    display: block; }
  .navbar .dropdown-menu {
    padding: 1.5em; }
  @media (max-width: 992px) {
    .navbar .navbar-nav {
      padding-top: 1rem; }
    .navbar .dropdown-menu {
      display: block;
      border: none;
      text-align: left;
      padding: 0; }
    .navbar .nav-link {
      text-align: left;
      font-weight: bold !important; } }
  .navbar .dropdown-menu .tree {
    list-style-type: none;
    padding-left: 20px;
    /* Style the caret/arrow */
    /* Create the caret/arrow with a unicode, and style it */ }
    .navbar .dropdown-menu .tree ul.nested {
      margin-top: 1em;
      margin-bottom: 1em;
      display: none; }
      .navbar .dropdown-menu .tree ul.nested.active {
        display: block; }
    .navbar .dropdown-menu .tree .caret {
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none;
      /* Prevent text selection */
      position: relative;
      color: black;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.05em; }
    .navbar .dropdown-menu .tree .caret::before {
      content: "+";
      position: absolute;
      left: -17px;
      color: #ff6600;
      display: inline-block;
      margin-right: 6px; }
    .navbar .dropdown-menu .tree .caret.active::before {
      content: "-"; }
    .navbar .dropdown-menu .tree .link {
      position: relative; }
    .navbar .dropdown-menu .tree .link::before {
      content: ">";
      position: absolute;
      left: -17px;
      color: #ff6600;
      display: inline-block;
      margin-right: 6px; }
    .navbar .dropdown-menu .tree ul {
      list-style-type: none;
      padding-left: 15px; }
    .navbar .dropdown-menu .tree a {
      color: black;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.05em; }
  .navbar.shrink {
    background-color: red; }
  .navbar .navbar-brand {
    padding: 10px 15px 10px 15px;
    height: auto; }
    .navbar .navbar-brand:hover:after, .navbar .navbar-brand:focus:after, .navbar .navbar-brand:active:after {
      width: 0; }
    @media (min-width: 992px) {
      .navbar .navbar-brand {
        position: absolute; } }
    .navbar .navbar-brand .logo {
      width: 240px; }
    @media (max-width: 991px) {
      .navbar .navbar-brand {
        padding: 0px 0; }
        .navbar .navbar-brand .logo {
          width: 90px; } }
  @media (min-width: 992px) {
    .navbar .navbar-collapse {
      height: auto !important; } }
  .navbar .intralink {
    display: inline-block;
    color: #fff;
    background-color: #ff6600;
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05em;
    transition: background-color 250ms ease-in-out;
    text-transform: uppercase; }
    .navbar .intralink:hover, .navbar .intralink:focus, .navbar .intralink:active {
      text-decoration: none;
      outline: none;
      background-color: #333333;
      color: #fff; }
      .navbar .intralink:hover:after, .navbar .intralink:focus:after, .navbar .intralink:active:after {
        width: 0; }
    .navbar .intralink.mobile {
      display: none; }
    @media (max-width: 991px) {
      .navbar .intralink.mobile {
        display: inline-block;
        width: 100px;
        margin: 20px auto; }
      .navbar .intralink.desktop {
        display: none; } }
  .navbar .navbar-nav {
    padding-left: 70px; }
    .navbar .navbar-nav a.nav-link {
      color: #333;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      position: relative;
      letter-spacing: 0.05em;
      padding: 1rem; }
      @media (max-width: 991px) {
        .navbar .navbar-nav a.nav-link {
          padding: 0.5rem; } }
      .navbar .navbar-nav a.nav-link:after {
        content: '';
        position: absolute;
        display: block;
        height: 2px;
        width: 0;
        background-color: #ff6600;
        bottom: 10px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        transition: width 250ms ease-in-out; }
      .navbar .navbar-nav a.nav-link.active:after {
        width: 30%; }
      @media (min-width: 992px) {
        .navbar .navbar-nav a.nav-link:hover:after {
          width: 30%; } }
    @media (max-width: 991px) {
      .navbar .navbar-nav {
        text-align: center; }
        .navbar .navbar-nav a.nav-link {
          transition: all 250ms ease-in-out; }
          .navbar .navbar-nav a.nav-link.active:after {
            width: 0; }
          .navbar .navbar-nav a.nav-link:hover:after {
            width: 0; }
          .navbar .navbar-nav a.nav-link.active, .navbar .navbar-nav a.nav-link:hover, .navbar .navbar-nav a.nav-link:focus {
            color: #ff6600; } }
  .navbar .navbar-toggle {
    overflow: hidden;
    background-color: transparent;
    border: none;
    margin-top: 0px;
    margin-bottom: 0;
    outline: none; }
    @media (min-width: 992px) {
      .navbar .navbar-toggle {
        display: none; } }
    .navbar .navbar-toggle .icon-bar {
      transition: opacity, -webkit-transform;
      transition: opacity, transform;
      transition: opacity, transform, -webkit-transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);
      background-color: #333;
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px; }
      .navbar .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 4px; }
    .navbar .navbar-toggle:not(.collapsed) .icon-bar:nth-child(1) {
      -webkit-transform: translateY(6px) rotate(45deg);
      transform: translateY(6px) rotate(45deg); }
    .navbar .navbar-toggle:not(.collapsed) .icon-bar:nth-child(2) {
      opacity: 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%); }
    .navbar .navbar-toggle:not(.collapsed) .icon-bar:nth-child(3) {
      -webkit-transform: translateY(-6px) rotate(-45deg);
      transform: translateY(-6px) rotate(-45deg); }

#recruitment-banner {
  position: relative;
  display: none;
  text-align: center;
  min-height: 3em;
  background-color: black;
  color: white;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0; }
  #recruitment-banner.visible {
    display: block; }
  #recruitment-banner span {
    line-height: 2.5em; }
  #recruitment-banner a.btn-blue {
    min-width: 12em;
    margin-left: 3em; }
  #recruitment-banner a.close-button {
    color: white;
    position: absolute;
    top: 0.5em;
    right: 1em;
    font-size: 30px; }
  @media (max-width: 768px) {
    #recruitment-banner {
      font-size: 0.8em; }
      #recruitment-banner a.close-button {
        top: 0.25rem;
        right: 0.25rem; } }

.footer {
  padding-top: 100px;
  background-color: black;
  color: #fff;
  font-size: 14px;
  line-height: 1.42857; }
  .footer img.logo {
    width: 300px;
    padding-bottom: 30px; }
  .footer .upper {
    padding-bottom: 100px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
  .footer .lower {
    padding-top: 30px;
    padding-bottom: 30px; }
    .footer .lower p {
      margin: 0; }
  @media screen and (max-width: 991px) {
    .footer {
      padding-top: 80px; }
      .footer .upper {
        padding-bottom: 40px; } }
  .footer .footer-nav {
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    margin: 0; }
    @media (min-width: 768px) {
      .footer .footer-nav {
        text-align: center; } }
    .footer .footer-nav > li {
      display: inline-block;
      padding: 5px 10px 5px 0; }
      @media (min-width: 768px) {
        .footer .footer-nav > li {
          padding: 5px 10px; } }
      @media screen and (max-width: 991px) {
        .footer .footer-nav > li {
          display: block; } }
      .footer .footer-nav > li > a {
        text-transform: uppercase;
        font-weight: 500;
        color: #fff; }
        .footer .footer-nav > li > a:hover, .footer .footer-nav > li > a:focus, .footer .footer-nav > li > a:active {
          color: #fff;
          text-decoration: none; }
      .footer .footer-nav > li.current a {
        font-weight: 300; }
  @media (min-width: 768px) {
    .footer .some {
      text-align: right; } }
  .footer .some .btn-blue {
    margin-top: 30px; }
    .footer .some .btn-blue:hover, .footer .some .btn-blue:focus, .footer .some .btn-blue:active {
      background-color: #ff6600; }
  .footer .some-list {
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    text-align: right;
    margin: 0; }
    .footer .some-list > li {
      display: inline-block; }
      .footer .some-list > li > a {
        color: #fff;
        display: block;
        padding: 0px 10px;
        font-size: 20px; }
        .footer .some-list > li > a:hover, .footer .some-list > li > a:focus, .footer .some-list > li > a:active {
          color: #fff;
          text-decoration: none; }
      .footer .some-list > li:last-child > a {
        padding-right: 0; }
  @media (min-width: 768px) {
    .footer .right {
      text-align: right; } }
  .footer .right a {
    color: #fff;
    font-weight: 500;
    text-transform: uppercase; }
  @media screen and (max-width: 620px) {
    .footer .upper > div {
      width: 100%; }
      .footer .upper > div .footer-nav {
        text-align: left;
        padding-top: 30px;
        padding-bottom: 15px; }
        .footer .upper > div .footer-nav li {
          padding-left: 0; }
      .footer .upper > div .some-list {
        text-align: left; }
        .footer .upper > div .some-list > li > a {
          padding-left: 0;
          padding-right: 20px; }
    .footer .lower > div {
      width: 100%;
      text-align: center; }
      .footer .lower > div.right {
        padding-top: 15px; } }

#content {
  scroll-margin-top: 6.5rem; }

.p-t-100 {
  padding-top: 100px; }

.p-t-75 {
  padding-top: 75px; }

.p-t-50 {
  padding-top: 50px; }

.p-t-25 {
  padding-top: 25px; }

.p-b-100 {
  padding-bottom: 100px; }

.p-b-75 {
  padding-bottom: 75px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-b-25 {
  padding-bottom: 20px; }

.text-center {
  text-align: center; }

.bg-white {
  background-color: #fff; }

.bg-gray {
  background-color: #fafafa !important; }

.intro h1 {
  font-weight: 600; }

.map {
  height: 400px; }

.juicer-feed li {
  border-radius: 5px !important; }

.igfeed, .bg-white {
  background-color: #fff; }

a.btn-standard {
  display: inline-block;
  color: #fff !important;
  background-color: #ff6600;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.05em;
  transition: background-color 250ms ease-in-out;
  text-transform: uppercase;
  border: none; }

a.btn-grey {
  display: inline-block;
  color: #fff !important;
  background-color: black;
  padding: 15px 50px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.05em;
  transition: background-color 250ms ease-in-out;
  text-transform: uppercase;
  border: none; }

.highlight {
  color: #ff6600; }

._inline-form {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
  max-width: none !important; }

.btn-blue {
  margin-bottom: 0.5rem; }

.bg-gray #_form_6609613699851_ {
  background-color: inherit !important; }

.bg-gray #_form_66096C1AEBF58_ {
  background-color: inherit !important; }

