@import '../../variables.scss';

.accordion{

    .card{

        .card-header{
            background-color:#fafafa;

            button{
                color: $mainColor;
                text-align: left;
            }
        }
    }
}