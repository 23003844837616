.service-page-other-links {
  padding-bottom: 50px;

  h2 {
    margin-bottom: 1.5em;
  }

  ul {
    list-style-type: none;
    font-size: 140%;
    margin-left: 0;
    padding-left: 0;

    li {
      margin-bottom: 1em;
      margin-left: 0;
    }
  }
}